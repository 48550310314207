<script setup>
import { useAsyncData } from "#app";

const backend = useBackend();
const route = useRoute();
const documentFound = ref(true);

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex, follow'
    }
  ]
})

const {data: document} = await useAsyncData(async () => {
  const {data} = await backend.getDocument(route.params.slug);
  if(Array.isArray(data)) {
    documentFound.value = false;
    return null;
  }
  return data;
});

if (!documentFound.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found'
  })
}

useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {name: 'robots', content: 'noindex, follow'},
    {rel: 'canonical', href: 'https://vehis.pl'}
  ]
});

</script>

<template>
  <Container>
    <div>
      <h1 class="text-vehis-red text-4xl uppercase mb-4">{{ document.title }}</h1>

      <div v-html="document.content"
           class="document-content font-light"
      ></div>
    </div>
  </Container>
</template>

<style lang="postcss" scoped>

.document-content:deep(h2) {
  @apply border-b border-b-gray-400 !text-2xl font-medium pb-4 mb-4;
}

.document-content:deep(ol) {
  @apply list-decimal list-inside;
}

.document-content:deep(li) {
  @apply mt-2;
}

.document-content:deep(ol) > li > ol {
  @apply list-[lower-alpha] list-inside ml-8;
}

.document-content:deep(a) {
  @apply underline;
}

</style>
